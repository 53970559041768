
/* 
将数组重组
eg: [1,2,3,4] => [[1,2],[3,4]]
*/
export function splitArrayIntoChunks<T>(arr: T[], chunkSize: number): T[][] {
  const result: T[][] = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
}

