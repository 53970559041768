<template>
    <!-- PC端 -->
    <div class="on-monitor">
        <section class="header-empty"></section>
        <section class="first-room">
            <aside>
                <h1>NEWS CENTER<span>新闻中心</span></h1>
            </aside>
        </section>
    </div>
    <!-- 移动端 -->
    <div class="on-mobile">
      <div class="banner">
        <h1>NEWS CENTER<span>新闻中心</span></h1>
      </div>
    </div>
    <router-view></router-view>
</template>
<!-- PC端 -->
<style lang="less">
.on-monitor {
    .header-empty {
        height: @hear-height;
    }
    .first-room {
        padding-top: 140px;
        padding-bottom: 90px;
        background: url("https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/news/topbg.png") center/cover no-repeat;
        aside {
            width: 1040px;
            margin: 0 auto;
            h1 {
                font-weight: 700;
                font-size: 40px;
                color: #fff;
                position: relative;
                span {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 1;
                    color: #fff;
                    padding: 8px 14px;
                    background-color: @theme-color;
                    border-radius: 22px 22px 22px 0px;
                    position: absolute;
                    left: 0px;
                    top: -50px;
                }
            }
        }
    }
    .second-room {
        .container {
            text-align: center;
            padding-bottom: 35px;
            user-select: none;
            p.crumbs {
                color: #999;
                text-align: left;
                span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 84px;
                    cursor: pointer;
                    &::before {
                        content: ">";
                        padding: 0px 5px;
                        display: inline-block;
                    }
                    &:first-child {
                        &::before {
                            display: none;
                        }
                    }
                    &:hover {
                        color: @theme-color;
                        &::before {
                            color: #999;
                        }
                    }
                }
            }
            .main {
                text-align: left;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                ul {
                    width: 18%;
                    border: 1px solid #E3E3E3;
                    border-radius: 10px;
                    li {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 62px;
                        padding-left: 48px;
                        border-top: 1px solid #E3E3E3;
                        position: relative;
                        cursor: pointer;
                        &::before {
                            content: "";
                            display: block;
                            width: 4px;
                            height: 4px;
                            border-radius: 50%;
                            background-color: #E3E3E3;
                            position: absolute;
                            left: 28px;
                            top: 28px;
                        }
                        &:first-child {
                            border-top: 0 none;
                        }
                        &.active {
                            color: @theme-color;
                            &::before {
                                width: 8px;
                                height: 8px;
                                left: 26px;
                                top: 26px;
                                background-color: @theme-color;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
<!-- 移动端 -->
<style scoped lang="less">
.on-mobile {
    .banner {
        background: url("https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/news/newsbanner.png") center/cover no-repeat;
        padding: 6rem 0px 3rem;
        h1 {
            font-weight: 700;
            letter-spacing: 0.1rem;
            font-size: 2rem;
            color: #fff;
            width: 80vw;
            margin: 0px auto;
            position: relative;
            span {
                letter-spacing: 0px;
                display: block;
                font-weight: 400;
                font-size: 1.2rem;
                line-height: 2rem;
                color: #fff;
                padding: 0px 1rem;
                background-color: @theme-color;
                border-radius: 2rem 2rem 2rem 0px;
                position: absolute;
                left: 0px;
                top: -3rem;
            }
        }
    }
}
</style>
