import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import  Store,{key} from './store'
import '@s/index.less' // 引入公共样式
// import Drag from './utils/drag' // 拖拽
import Fadein from './utils/fadein' // 渐显
import Suspend from './utils/suspend' // 按钮悬浮光点
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 注册ElementPlus所有图标
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

createApp(App)
.use(Store,key)
.use(router)
.use(Fadein)
.use(Suspend)
.use(ElementPlus)
.mount('#app')
