// index.ts
import {createStore,Store} from 'vuex'
import {InjectionKey} from 'vue'

export const key: InjectionKey<Store<state>> = Symbol('key')

export type state ={
  menuid:string, // 菜单id
  menuPhoneFlag:boolean, // 移动端-菜单窗口
  reservationFlag:boolean, // PC端-预约咨询窗口
  settledFlag:boolean, // PC端-入驻窗口
  settledType:number, // PC端-入驻类型：1、机构；2、服务商
  counsellingFlag:boolean, // 移动端-预约咨询窗口
}
interface settledConfig {
    flag: boolean;
    type: number;
  }
export default createStore({
    state:{
      menuid:"1",
      reservationFlag:false,
      counsellingFlag:false,
      menuPhoneFlag:false,
      settledFlag:false,
      settledType: 0
    },
    mutations:{
      changePage(state,val){
        state.menuid = val;
      },
      reservationShow(state,flag){
        state.reservationFlag = flag;
      },
      settledActive(state,d:settledConfig){
        state.settledFlag = d.flag;
        state.settledType = d.type
      },
      counsellingPhoneShow(state,flag){
        state.counsellingFlag = flag;
      },
      menuPhoneShow(state,flag){
        state.menuPhoneFlag = flag;
      },
    }
  })
