
// 渐显效果
const fadein = {
    mounted(el: HTMLElement) {
      let fadeEl = el as HTMLElement;
      // 没有类名forithmrunning，则添加渐显效果
      if(!fadeEl.classList.value.includes("forithmrunning")){
        fadeEl.classList.add('forithmpaused');
        window.addEventListener("scroll", ()=>{
          let {top} = fadeEl.getBoundingClientRect();
          // 元素顶部到屏幕可视区一半时渐显
          if(top/window.innerHeight <= 0.88){
              fadeEl.classList.add('forithmrunning');
          }
        });
      }
    },
  }
   
  const directives = {
    install: function (app: any) {
      app.directive('fadein', fadein)
    }
  }
  export default directives