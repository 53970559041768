
import { ref } from 'vue';

// 环境
// export const baseUrl = ref("http://192.168.0.65/octopuscms/website/");
export const baseUrl = ref("https://bazhuajiaoyu.com/octopuscms/website/");

// 入驻
// export const ifraUrl = ref("http://192.168.0.65/");
export const ifraUrl = ref("https://bazhuaedu.com/");

// 机构APP下载-中转页面
export const InstitutionalAPP = ref("https://bazhuaedu.com/orgapp/baidutongji/bootDownload.html");

// 公共头部-所有目录
export const menus = ref<any>([
    {id: "1", title: "关于八爪"},
    {id: "2", title: "产品服务"},
    {id: "3", title: "新闻中心"},
    {id: "4", title: "机构入驻"},
    {id: "5", title: "服务商入驻"},
]);

// 他们都在用八爪招生
export const themList = ref([
    {
      title: "安可音乐",
      description: "美术｜音乐｜书法",
      picture: "https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/public/them1.png",
      logo: "https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/public/them01.png",
    },
    {
      title: "小天鹅艺术中心",
      description: "美术｜声乐｜舞蹈｜表演｜书法｜播音主持｜跆拳道",
      picture: "https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/public/them2.png",
      logo: "https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/public/them02.png",
    },
    {
      title: "编程猫",
      description: "少儿编程｜机器人｜创客教育｜思维能力",
      picture: "https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/public/them3.png",
      logo: "https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/public/them03.png",
    },
    {
      title: "三千小童",
      description: "书法｜国艺｜棋艺",
      picture: "https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/public/them4.png",
      logo: "https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/public/them04.png",
    },
    {
      title: "龙辰跆拳道",
      description: "跆拳道",
      picture: "https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/public/them5.png",
      logo: "https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/public/them05.png",
    },
    {
      title: "余悦舞蹈",
      description: "舞蹈",
      picture: "https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/public/them6.png",
      logo: "https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/public/them06.png",
    },
    {
      title: "南京CW篮球",
      description: "球类",
      picture: "https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/public/them7.png",
      logo: "https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/public/them07.png",
    },
    {
      title: "正学书院",
      description: "书法",
      picture: "https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/public/them8.png",
      logo: "https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/public/them08.png",
    },
]);

// 招生服务列表
export const advantageList = ref([
    {
      title:'线下地推招生',
      desc:'7000专职业务员，1对1精准拉新；3-5天招满，100%真实生源',
      picture:'https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/product/se1.png',
      children: ['移动端随时随地投放招生需求','24小时响应接单','根据效果，随时开启暂停和续招','按效果付费，获客成本低至几十']
    },
    {
      title:'线上裂变招生',
      desc:'资深运营团队，活动全案策划，老带新，生源极速裂变',
      picture:'https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/product/se2.png',
      children: ['Day 1：活动策划，老师培训','Day 2：选定团长，裂变新生','Day 3：持续裂变，全面收割','校区提供课时坐享生源滚滚']
    },
    {
      title:'在线营销服务',
      desc:'满足机构多种营销需求，帮助机构开源增收',
      picture:'https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/product/se3.png',
      children: ['课程服务：在线发布各类课程（体验课、正价课）','内容服务：短视频、校区动态、校区特色照片','营销服务：优惠券、拼团、秒杀、抽奖','增值服务：场地共享、科目共享、师资共享、生源共享']
    },
    {
      title:'商城批发服务',
      desc:'链接优质供应链源头，提供玩家、教具教材批发服务',
      picture:'https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/product/se4.png',
      children: ['优质源头','1件起批','品类丰富','品质保障']
    },
    {
      title:'八爪会员服务',
      desc:'开启全国合伙人模式，加盟八爪会员，共享招生收益',
      picture:'https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/product/se5.png',
      children: ['招生专属优惠','招生推广收益','会员推广权益','佣金高达50%']
    },
])

// 新闻轮播图
export let newsPicList: string[] = [];
for(let i=1;i<6;i++){
  newsPicList.push("https://bazhuaedu.oss-cn-shanghai.aliyuncs.com/edu/Official/public/new"+i+".png");
}
