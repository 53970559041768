
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import homeView from '@/views/homeView.vue' // 基础页面

//  PC端页面
import octopus from '@/views/pages/octopus.vue' // 关于八爪
import consumer from '@/views/pages/consumer.vue' // 产品服务-用户
import mechanism from '@/views/pages/mechanism.vue' // 产品服务-机构
import serve from '@/views/pages/serve.vue' // 产品服务-优服
import news from '@/views/pages/news.vue' // 新闻
import newsList from '@/views/pages/news/list.vue' // 新闻列表
import newsDetail from '@/views/pages/news/detail.vue' // 新闻详情
import convention from '@/views/pages/convention.vue' // 八爪教育平台自律公约

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: homeView,
    children: [
      {
        path: '',
        name: 'octopus',
        meta: { lv: "1", title: "关于八爪" },
        component: octopus
      },
      {
        path: 'consumer',
        name: 'consumer',
        meta: { lv: "2", title: "八爪畅学用户端" },
        component: consumer
      },
      {
        path: 'mechanism',
        name: 'mechanism',
        meta: { lv: "2", title: "八爪畅学机构端" },
        component: mechanism
      },
      {
        path: 'serve',
        name: 'serve',
        meta: { lv: "2", title: "八爪优服" },
        component: serve
      },
      {
        path: '/news',
        name: 'news',
        component: news,
        children:[
          {
            path: 'list',
            name: 'newsList',
            meta: { lv: "3", title: "新闻中心" },
            component: newsList
          },
          {
            path: 'detail',
            name: 'newsDetail',
            meta: { lv: "3", title: "新闻中心" },
            component: newsDetail
          }
        ]
      },
      {
        path: 'convention',
        name: 'convention',
        meta: { lv: "2", title: "八爪教育平台自律公约" },
        component: convention
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
