<template>
    <button class="double-deck">
        <span><slot></slot></span>
        <div class="fuzzy-interval">
            <canvas v-suspend></canvas>
        </div>
    </button>
</template>

<script setup lang="ts">

</script>

<style scoped lang="less">
button.double-deck {
  width: 200px;
  height: 50px;
  border-radius: 50px;
  background-color: @theme-color;
  position: relative;
  z-index: 1;
  span {
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    position: relative;
    z-index: 3;
    pointer-events: none;
  }
  .fuzzy-interval {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 200px;
    height: 50px;
    border-radius: 50px;
    overflow: hidden;
    canvas {
      filter: blur(10px);
    }
  }
  &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50px;
      background-color: @theme-color;
      opacity: 0.6;
      position: absolute;
      top: 6px;
      left: 0px;
      z-index: -1;
  }
  &:active {
      transform: translateY(2px);
      &::after {
          transform: translateY(-2px);
      }
  }
}
</style>