
const suspend = {
    mounted(el: any) {
      // 保持画布大小
      el.width = 200;
      el.height = 50;
      const ctx = el.getContext("2d");

      // 鼠标悬浮触发事件
      el.addEventListener("mousemove", function(e:any) {
        const rect = el.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        // 清除画布
        ctx.clearRect(0, 0, el.width, el.height);

        // 绘制一个圆，跟随鼠标移动
        ctx.beginPath();

        // 圆半径
        const radius = 15;

        // 圆形
        ctx.arc(x, y, radius, 0, Math.PI * 2);

        // 设置阴影颜色和模糊半径
        // ctx.shadowColor = 'rgb(255, 179, 59, 0.8)';
        // ctx.shadowBlur = 50;
        // ctx.closePath();
        // ctx.fillStyle = '#ffb33b'; // 圆点

        // 描边
        // ctx.fillStyle = '#ffb33b';
        // ctx.strokeStyle = '#000';
        // ctx.stroke();
        // ctx.fill();

        // 球体
        // let grd=ctx.createRadialGradient(10,10,10,30,30,30);
        // grd.addColorStop(0.5,'#ffb33b');
        // grd.addColorStop(1,'#FF6225');
        // ctx.fillStyle=grd;
        // ctx.fillRect(10,10,20,20);

        ctx.globalCompositeOperation = 'source-over';

        // 拟光
        ctx.fillStyle = "#ffb33b";
        // ctx.filter = "blur(10px)";
        // ctx.shadowBlur = 10;
        ctx.fill();
      });
      // 鼠标移出清空效果
      el.addEventListener("mouseout", function() {
        // 清除画布
        ctx.clearRect(0, 0, el.width, el.height);
      });
    },
  }
   
  const directives = {
    install: function (app: any) {
      app.directive('suspend', suspend)
    }
  }

  export default directives