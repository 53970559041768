import instance from "./http";
 
// Res是返回的参数，T是泛型，需要自己定义，返回对数统一管理***
type Res<T> = Promise<ItypeAPI<T>>;
// 一般情况下响应数据返回的这三个参数，
// 但不排除后端返回其它的可能性，
interface ItypeAPI<T> {
    data: T,//请求的数据，用泛型
    msg: string | null // 返回状态码的信息，如请求成功等
    code: number //返回后端自定义的200，404，500这种状态码
}

/* 
    新闻列表
    title String 搜索标题
    newsType String 主键id
    page String 页数
    limit String 条数
*/
interface newsListConfig {
    title: string;
    newsType: string;
    page: number;
    limit: number;
    year: string;
  }
export const newsListHttp = (data:newsListConfig): Res<any> => instance.post("news/list", data);
/* 
    新闻分类
*/
export const newsTypeListHttp = (): Res<null> => instance.post("newstype/list");
/* 
    发展历史列表
    title String 搜索标题
*/
interface historyListConfig {
    title: string;
    page: number;
    limit: number;
  }
export const historyListHttp = (data:historyListConfig): Res<any> => instance.post("history/list", data);
/* 
    新闻列表获取年份
*/
export const yearListHttp = (): Res<null> => instance.post("news/getNewYears");
/* 
    新闻详情
*/
interface detailConfig {
    id: string|number
}
export const newsDetailHttp = (data: detailConfig): Res<null> => instance.post(`news/info/${data.id}`);
/* 
    合作机构
*/
interface partnerConfig {
    page: number;
    limit: number;
}
export const partnerListHttp = (data:partnerConfig): Res<any> => instance.post("partners/list", data);
/* 
    服务团队
*/
interface serveConfig {
    page: number;
    limit: number;
}
export const serveListHttp = (data:serveConfig): Res<any> => instance.post("serviceteam/list", data);

/* 
    获取schema
    type 0小程序用户端，1小程序机构端
    path 页面路径
    默认值"release"。要打开的小程序版本。正式版为"release"，体验版为"trial"，开发版为"develop"，仅在微信外打开时生效。
*/
interface wxConfig {
    type: number;
    path: string;
    env_version: string
}
export const wxSchema = (data:wxConfig): Res<any> => instance.post("channel/getAppScheme", data);


/* 
    #微信小程序配置
    wxopen:
    appId: wxa6609a2c5cd62527
    appSecret: 378d74abda1679eb4f9fc57583f958e5
    orgAppId: wxeb90ce81074417ef
    orgAppSecret: 6d438591472063bbdd4b9e4e098eff29
*/
// 跳转用户小程序
let userData = {
"grant_type":"client_credential",
"appid":"wxa6609a2c5cd62527",
"secret":"378d74abda1679eb4f9fc57583f958e5",
}
// 跳转机构小程序
let orgData = {
"grant_type":"client_credential",
"appid":"wxeb90ce81074417ef",
"secret":"6d438591472063bbdd4b9e4e098eff29",
}

  









 
// post请求 ，没参数
// export const LogoutAPI = (): Res<null> => instance.post("/admin/logout");
 
// post请求，有参数,如传用户名和密码
// export const loginAPI = (data: ReqLogin): Res<string> =>
//     instance.post("/admin/login", data);
 
// post请求 ，没参数，但要路径传参
// export const StatusAPI = (data: ReqStatus): Res<null> =>
//     instance.post(`/productCategory?ids=${data.id}&navStatus=${data.navStatus}`);
 
 
//  get请求，没参数，
// export const FlashSessionListApi = (): Res<null> =>
//     instance.get("/flashSession/list");
 
// get请求，有参数，路径也要传参  (也可能直接在这写类型，不过不建议,大点的项目会维护一麻烦)
// export const ProductCategoryApi = (params: { parentId: number }): Res<any> =>
//     instance.get(`/productCategory/list/${params.parentId}`, { params });
 
// get请求，有参数，(如果你不会写类型也可以使用any,不过不建议,因为用了之后 和没写TS一样)
// export const AdminListAPI = (params:any): Res<any> => instance.get("/admin/list", { params });
 